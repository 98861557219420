import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

import Layout from '../components/Layout'
import '../components/base.css'
import { WarrantyReturnsQuery } from '../../graphql-types'
import DocumentContent from '../components/DocumentContent'
import extractGlobals from '../lib/extractGlobals'

const WarrantyReturns: FC<PageProps<WarrantyReturnsQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <DocumentContent
        title={data.prismicWarrantyReturns?.data?.title?.text}
        content={data.prismicWarrantyReturns?.data?.content?.html}
      />
    </Layout>
  )
}

export default withPreview(WarrantyReturns)

export const pageQuery = graphql`
  query WarrantyReturns {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    prismicWarrantyReturns {
      data {
        title {
          text
        }
        content {
          html
          raw
        }
      }
    }
  }
`
